import { assign } from "@xstate/immer";
import { CoverPhotos } from "api/models/CoverPhotos";
import { GetPricesResponse } from "api/models/GetPricesResponse";
import { Property } from "api/models/Property";
import { RoomRouteParams } from "common/hooks/useRoomParams";
import { IS_TRY_IT_OUT_ENABLED } from "constants/env";
import { ROOMS_PIECES_NAMES } from "constants/rooms/roomPieces";
import {
  getPlanningRoomsList,
  getUpdatedRoomNames,
} from "constants/rooms/rooms.utils";
import { SubCategory } from "constants/subcategories/subcategory.types";
import {
  EphemeralSidebarType,
  OrchestratorEvent,
  OrchestratorGuideDoneEventData,
  OrchestratorHIPSOnboardingContext,
  OrchestratorMachineContext,
  OrchestratorSetActivePropertyEventData,
  OrchestratorSOWGuide,
  OrchestratorSOWGuideStatus,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { useStableNavigate } from "core/state/hooks/useStableNavigate";
import { uniq, uniqBy } from "lodash-es";
import { RoomsAndSpacesActionData } from "pages/EditRoomsAndSpaces/components/EditRoomsAndSapcesContent/EditRoomsAndSpacesAddRoomPanel/EditRoomsAndSpacesAddRoomPanel.types";
import { EditRoomsAndSpacesSaveEventData } from "pages/EditRoomsAndSpaces/EditRoomsAndSpaces.types";
import { isPriceStale } from "pages/Guides/budget/utils/isPriceStale";
import { GUIDE_INTENTS_ORDER } from "pages/Guides/consts";
import { GuideIntentType } from "pages/Guides/enums";
import {
  MeasurementsShownData,
  RemovePinnedPhotoData,
  SavePinnedPhotoData,
} from "pages/Guides/tabs/GuideImagesTab/components/ImagePanel/ImagePanelContent/types";
import { InspirationUploadMapping } from "pages/Guides/tabs/GuideInspireTab/InspirationPanel/types";
import { BaseActiveGuideContext, GuideIntents } from "pages/Guides/types";
import {
  isGuideOnIDKPause,
  processGuideSpecificData,
} from "pages/Guides/utils/utils.results";
import {
  Image,
  PropertyCardImage,
  PropertyCardImageProPayload,
  SetHIPsImagePayload,
} from "pages/Media/types";
import {
  RoomAndSpace,
  RoomCategoryMap,
  RoomGuideResults,
} from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { TFunction } from "react-i18next";
import { HIPsRouteParams } from "router/models";
import {
  HIPS_DASHBOARD,
  HIPS_ONBOARDING,
  HIPS_ROOM,
  MEDIA,
  TRY_ROUTES_PATHS,
} from "router/routes";
import { findBy } from "shared/util/findBy";
import { getKeys } from "shared/util/getKeys";
import { uuid } from "shared/util/uuid";

import {
  createPropertyContext,
  getActivePropertyContext,
  mergeGuideResults,
  setActivePropertyId,
  updateHipsRoomsWithEditedChanges,
  updateMediaCoverPhotos,
  updateRoomsWithEditedChanges,
} from "./actions.utils";
import { OrchestratorConfirmationWarningEvent } from "./confirmationWarning/models";
import { PRO_ACCOUNT_FAKE_PROPERTY_ID } from "./constants";
import { createHIPsContext } from "./hips/HIPsContext";
import { ISaveInspirationMappingData } from "./inspiration/InspirationActions";

export const getOrchestratorActions = ({
  t,
  navigate,
}: {
  t: TFunction;
  navigate: ReturnType<typeof useStableNavigate>;
}) => {
  const actions = {
    ephemeral: {
      help: assign((ctx: OrchestratorMachineContext) => {
        ctx.ephemeral = { ...ctx.ephemeral, sidebar: "help" };
      }),
      reportBug: assign((ctx: OrchestratorMachineContext) => {
        ctx.ephemeral = { ...ctx.ephemeral, sidebar: "bug" };
      }),
      makeSuggestion: assign((ctx: OrchestratorMachineContext) => {
        ctx.ephemeral = { ...ctx.ephemeral, sidebar: "suggestion" };
      }),
      subscribe: assign((ctx: OrchestratorMachineContext) => {
        ctx.ephemeral = { ...ctx.ephemeral, sidebar: "subscribe" };
      }),
      openSidebar: assign(
        (
          ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<EphemeralSidebarType>
        ) => {
          if (event.data) {
            ctx.ephemeral.sidebar = event.data;
          }
        }
      ),
      closeSidebar: assign((ctx: OrchestratorMachineContext) => {
        ctx.ephemeral.sidebar = null;
      }),
      openAnyModal: assign((ctx: OrchestratorMachineContext) => {
        ctx.ephemeral.isAnyModalOpen = true;
      }),
      closeAnyModal: assign((ctx: OrchestratorMachineContext) => {
        ctx.ephemeral.isAnyModalOpen = false;
      }),
    },
    property: {
      setPropertyLocked: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<number>
        ) => {
          const ctx = _ctx.properties.find(
            (item) => item.property.id === event.data
          );
          if (ctx) {
            ctx.property.locked = true;
          }
        }
      ),
      setPropertyUnlocked: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<number>
        ) => {
          const ctx = _ctx.properties.find(
            (item) => item.property.id === event.data
          );
          if (ctx) {
            ctx.property.locked = false;
          }
        }
      ),
      // Note(Andrei): Most of our platform is tied to a property
      // but professional account doesn't have one.
      // So in order to allow him use some of the platform features
      // it's easier to create a fake property instead of refactoring everything.
      // see https://tree.taiga.io/project/homebase-engineering/us/1314
      setSingleFakeProperty: assign((ctx: OrchestratorMachineContext) => {
        const property = {
          id: PRO_ACCOUNT_FAKE_PROPERTY_ID,
        };
        const hasFakeProperty = ctx.properties.some(
          (it) =>
            it.property.id === property.id &&
            it.property.normalized_address_line1 === undefined &&
            it.property.normalized_address_line2 === undefined
        );

        if (hasFakeProperty) {
          return;
        }

        ctx.properties.push({
          ...createPropertyContext(),
          property,
        });
        ctx.state.activePropertyId = property.id;
      }),
      setProperty: assign(
        (
          ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<Property>
        ) => {
          const property = {
            id: uuid(),
            ...event.data,
          };
          ctx.properties = ctx.properties.filter(
            (it) => it.property.id !== property.id
          );
          ctx.properties.push({
            ...createPropertyContext(),
            property,
          });
          ctx.state.activePropertyId = property.id;
        }
      ),
      selectProperty: assign(
        (ctx: OrchestratorMachineContext, event: OrchestratorEvent<number>) => {
          ctx.state.activePropertyId = event.data;
        }
      ),
      setPropertyField: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<{ field: string; value: number }>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const { field, value } = event.data || {};
          ctx.property[field] = value;
        }
      ),
      setRoomsAndSpaces: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<RoomCategoryMap>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const data = event.data;
          const keys = getKeys(data);
          const roomNames = ROOMS_PIECES_NAMES(t);

          const roomsAndSpaces: RoomAndSpace[] = [];

          for (const key of keys) {
            const value = data[key];
            const types = getKeys(value);

            for (const type of types) {
              const count = value[type];

              for (let i = 0; i < count; i++) {
                const roomName = roomNames[type];
                const name = count > 1 ? `${roomName} ${i + 1}` : roomName;

                roomsAndSpaces.push({
                  id: uuid(),
                  category: key,
                  type,
                  name,
                });
              }
            }
          }
          ctx.rooms = roomsAndSpaces;
        }
      ),
      removeProperty: assign(
        (ctx: OrchestratorMachineContext, event: OrchestratorEvent<number>) => {
          ctx.properties = ctx.properties.filter(
            (it) => it.property.id !== event.data
          );
        }
      ),
    },
    prices: {
      updatePrices: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<GetPricesResponse>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const laborCategories = getKeys(event.data.labor.categories);
          const laborTypesCategories = event.data.labor.types
            ? getKeys(event.data.labor.types)
            : [];
          const itemsCategories = getKeys(event.data.items.categories);
          const typesCategories = getKeys(event.data.items.types);
          const subtypesCategories = event.data.items.subtypes
            ? getKeys(event.data.items.subtypes)
            : [];
          const updatedAt = new Date().toUTCString();

          for (const category of laborCategories) {
            const intents = getKeys(event.data.labor.categories[category]);

            for (const intent of intents) {
              const currentCategory = ctx.prices.labor.categories[category];
              const currentPrice = currentCategory
                ? currentCategory[intent]
                : undefined;
              const newPrice = event.data.labor.categories[category][intent];

              if (isPriceStale(currentPrice)) {
                if (!ctx.prices.labor.categories[category]) {
                  ctx.prices.labor.categories[category] = {};
                }

                ctx.prices.labor.categories[category][intent] = {
                  ...currentPrice,
                  amount: newPrice.amount,
                  type: newPrice.type,
                  updatedAt,
                };
              }
            }
          }

          for (const category of laborTypesCategories) {
            const types = Object.keys(event.data.labor.types[category]);
            for (const type of types) {
              const intents = Object.keys(
                event.data.labor.types[category][type]
              );
              for (const intent of intents) {
                const currentPrice =
                  ctx.prices.labor.types?.[category]?.[type]?.[intent];
                const newPrice = event.data.labor.types[category][type][intent];

                if (isPriceStale(currentPrice)) {
                  if (!ctx.prices.labor.types) {
                    ctx.prices.labor.types = {};
                  }
                  if (!ctx.prices.labor.types[category]) {
                    ctx.prices.labor.types[category] = {};
                  }
                  if (!ctx.prices.labor.types[category][type]) {
                    ctx.prices.labor.types[category][type] = {};
                  }
                  if (!ctx.prices.labor.types[category][type][intent]) {
                    ctx.prices.labor.types[category][type][intent] = {};
                  }

                  ctx.prices.labor.types[category][type][intent] = {
                    ...currentPrice,
                    amount: newPrice.amount,
                    type: newPrice.type,
                    updatedAt,
                  };
                }
              }
            }
          }

          for (const category of itemsCategories) {
            const currentPrice = ctx.prices.items.categories[category];
            const newPrice = event.data.items.categories[category];

            if (isPriceStale(currentPrice)) {
              ctx.prices.items.categories[category] = {
                ...currentPrice,
                amount: newPrice.amount,
                type: newPrice.type,
                updatedAt,
              };
            }
          }

          for (const category of typesCategories) {
            const types = Object.keys(event.data.items.types[category]);
            for (const type of types) {
              const currentPrice = ctx.prices.items.types[category]?.[type];
              const newPrice = event.data.items.types[category][type];

              if (isPriceStale(currentPrice)) {
                if (!ctx.prices.items.types[category]) {
                  ctx.prices.items.types[category] = {};
                }

                ctx.prices.items.types[category][type] = {
                  ...currentPrice,
                  amount: newPrice.amount,
                  type: newPrice.type,
                  updatedAt,
                };
              }
            }
          }

          for (const category of subtypesCategories) {
            const subtypes = Object.keys(event.data.items.subtypes[category]);
            for (const subtype of subtypes) {
              const types = Object.keys(
                event.data.items.subtypes[category][subtype]
              );
              for (const type of types) {
                const currentPrice =
                  ctx.prices.items.subtypes?.[category]?.[subtype]?.[type];
                const newPrice =
                  event.data.items.subtypes[category][subtype][type];

                if (isPriceStale(currentPrice)) {
                  if (!ctx.prices.items.subtypes) {
                    ctx.prices.items.subtypes = {};
                  }
                  if (!ctx.prices.items.subtypes[category]) {
                    // NOTE(clemens): 🤷 any is fine; we're just merging the object here
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ctx.prices.items.subtypes[category] = {} as any;
                  }
                  if (!ctx.prices.items.subtypes[category][subtype]) {
                    ctx.prices.items.subtypes[category][subtype] = {};
                  }
                  if (!ctx.prices.items.subtypes[category][subtype][type]) {
                    ctx.prices.items.subtypes[category][subtype][type] = {};
                  }

                  ctx.prices.items.subtypes[category][subtype][type] = {
                    ...currentPrice,
                    amount: newPrice.amount,
                    type: newPrice.type,
                    updatedAt,
                  };
                }
              }
            }
          }
        }
      ),
    },
    media: {
      saveInspirationMapping: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<InspirationUploadMapping>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const hasInspiration = findBy(
            ctx.inspiration.savedInspiration,
            "inspirationId",
            event.data.inspirationId
          );
          if (!hasInspiration) {
            ctx.inspiration.savedInspiration.push(event.data);
          }
        }
      ),
      setPropertyCardImage: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<PropertyCardImage>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          ctx.media.propertyCardImage = event.data;
        }
      ),
      setHipsCardImage: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<SetHIPsImagePayload>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const hip = findBy(ctx.hips, "id", event.data.hipsId);
          hip.coverPhoto = event.data.image;
        }
      ),
      setCoverPhotos: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<CoverPhotos>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const { data } = event;
          const ids = new Set(Object.values(data).map((img) => img.id));
          const cCoverPhotos = { ...ctx.media.coverPhotos };
          Object.entries(cCoverPhotos).forEach(([room, image]) => {
            if (ids.has(image.id)) {
              delete cCoverPhotos[room];
            }
          });

          const mappedCoverPhotos = Object.entries(data).reduce(
            (acc, [room, image]) => {
              if (ctx.media.coverPhotos[room]?.id === image.id) {
                delete acc[room];
              } else {
                acc[room] = {
                  id: image.id,
                  thumbnail_url: image.thumbnail_url,
                  thumbnail_url_fallback: image.thumbnail_url_fallback,
                };
              }
              return acc;
            },
            { ...cCoverPhotos }
          );
          ctx.media.coverPhotos = mappedCoverPhotos;
        }
      ),
      removePropertyCardImage: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<Array<Image["id"]>>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const { data } = event;
          if (data.includes(ctx.media.propertyCardImage?.id)) {
            ctx.media.propertyCardImage = null;
          }
        }
      ),
      removeCoverPhotos: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<Array<Image["id"]>>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const { data } = event;
          const ids = new Set(data);
          const cCoverPhotos = { ...ctx.media.coverPhotos };

          for (const [roomId, coverPhoto] of Object.entries(cCoverPhotos)) {
            if (ids.has(coverPhoto.id)) {
              delete cCoverPhotos[roomId];
            }
          }
          ctx.media.coverPhotos = cCoverPhotos;
        }
      ),
      removeCoverPhotosForRoom: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<{ roomId: string }>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const roomId = event.data.roomId;
          const cCoverPhotos = { ...ctx.media.coverPhotos };

          delete cCoverPhotos[roomId];

          ctx.media.coverPhotos = cCoverPhotos;
        }
      ),
      removePinnedPhoto: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<Array<Image["id"]>>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const ids = new Set(event.data);

          for (const [roomId, pinnedPhoto] of Object.entries(
            ctx.media.pinnedPhotos
          )) {
            if (ids.has(pinnedPhoto.id)) {
              delete ctx.media.pinnedPhotos[roomId];
            }
          }
        }
      ),
      removeInspirationMapping: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<number[]>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const ids = new Set(event.data);
          ctx.inspiration.savedInspiration =
            ctx.inspiration.savedInspiration.filter((inspiration) => {
              return !ids.has(inspiration.mediaId);
            });
        }
      ),
      setHasSeenMediaHelperText: assign((ctx: OrchestratorMachineContext) => {
        ctx.state.hasSeenMediaHelperText = true;
      }),
      setHasSeenMyAlbumsSidebar: assign((ctx: OrchestratorMachineContext) => {
        ctx.state.hasMyAlbumsSidebarOpened = true;
      }),
      setHasSeenConfirmationWarning: assign(
        (
          ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<OrchestratorConfirmationWarningEvent>
        ) => {
          ctx.state.hasSeenConfirmationWarning[event.data.type] = true;
        }
      ),
    },
    dashboard: {
      hips: {
        openRoom: (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<RoomRouteParams>
        ) => {
          const ctx = getActivePropertyContext(_ctx);

          navigate(
            HIPS_ROOM({
              propertyId: ctx.property.id,
              hipsId: event.data.hipsId,
              roomId: event.data.roomId,
            })
          );
        },
        openHips: [
          assign(
            (
              ctx: OrchestratorMachineContext,
              event: OrchestratorEvent<HIPsRouteParams>
            ) => {
              setActivePropertyId(ctx, event.data.propertyId);
            }
          ),
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<HIPsRouteParams>
          ) => {
            navigate(HIPS_DASHBOARD(event.data));
          },
        ],
        createHips: [
          (_ctx: OrchestratorMachineContext) => {
            const ctx = getActivePropertyContext(_ctx);
            const propertyId = ctx.property.id;
            const hipsId = uuid();
            navigate(HIPS_ONBOARDING({ propertyId, hipsId }));
          },
        ],
        removeItem: assign(
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<string>
          ) => {
            const ctx = getActivePropertyContext(_ctx);
            const idToRemove = event.data;

            ctx.hips = ctx.hips.filter((hips) => hips.id !== idToRemove);
            delete _ctx.state.getEducated.progressData[idToRemove];
            ctx.activeGuides = ctx.activeGuides.filter(
              (guide) => guide._hipId !== idToRemove
            );
          }
        ),
      },
      setHasFinishedOnboarding: assign(
        (
          ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<HIPsRouteParams>
        ) => {
          if (!ctx.state.hasFinishedOnboarding) {
            ctx.state.hasFinishedOnboarding = true;
          }
        }
      ),
      setIsProOnboarding: assign((ctx: OrchestratorMachineContext) => {
        ctx.state.isProOnboarding = true;
      }),
      seeInMyGallery: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<string>
        ) => {
          const ctx = getActivePropertyContext(_ctx);

          navigate(
            MEDIA({ propertyId: ctx.property.id }, { filters: event.data })
          );
        }
      ),
    },
    businessDashboard: {
      setClientImages: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<PropertyCardImageProPayload>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const { clientId, image } = event.data;
          ctx.media.propertyCardImagesMappingPro[clientId] = image;
        }
      ),
    },
    hips: {
      setTitle: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<{
            hipsId: string;
            title: string;
          }>
        ) => {
          const { hipsId, title } = event.data;
          const ctx = getActivePropertyContext(_ctx);
          const hip = findBy(ctx.hips, "id", hipsId);
          hip.onboarding.title = title;
        }
      ),
      setBudget: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<{
            hipsId: string;
            value: number;
          }>
        ) => {
          const { hipsId, value } = event.data;
          const ctx = getActivePropertyContext(_ctx);
          const hip = findBy(ctx.hips, "id", hipsId);
          hip.onboarding.budget = value;
        }
      ),
      setReserve: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<{
            hipsId: string;
            value: number;
          }>
        ) => {
          const { hipsId, value } = event.data;
          const ctx = getActivePropertyContext(_ctx);
          const hip = findBy(ctx.hips, "id", hipsId);
          hip.onboarding.reserve = value;
        }
      ),
      setHasSeenHipTour: assign((ctx: OrchestratorMachineContext) => {
        ctx.state.hasSeenHipTour = true;
      }),
      onboarding: {
        dashboard: [
          assign(
            (
              _ctx: OrchestratorMachineContext,
              event: OrchestratorEvent<{
                hipsId: string;
                onboarding: OrchestratorHIPSOnboardingContext;
              }>
            ) => {
              const ctx = getActivePropertyContext(_ctx);
              ctx.hips.push({
                ...createHIPsContext(),
                onboarding: event.data.onboarding,
                id: event.data.hipsId,
              });
            }
          ),
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<{
              hipsId: string;
              onboarding: OrchestratorHIPSOnboardingContext;
            }>
          ) => {
            const ctx = getActivePropertyContext(_ctx);
            const propertyId = ctx.property.id;
            const { hipsId } = event.data;
            navigate(HIPS_DASHBOARD({ propertyId, hipsId }));
          },
        ],
      },
    },
    guides: {
      guide: {
        addRooms: assign(
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<RoomsAndSpacesActionData[]>
          ) => {
            const ctx = getActivePropertyContext(_ctx);

            for (const room of event.data) {
              const { category, type } = room;
              const count = ctx.rooms.filter(
                (room) => room.type === type
              ).length;
              const name = ROOMS_PIECES_NAMES(t)[type];

              ctx.rooms.push({
                id: uuid(),
                category,
                type,
                name: `${name} ${count + 1}`,
              });
            }
          }
        ),
        saveProgress: [
          assign(
            (
              _ctx: OrchestratorMachineContext,
              event: OrchestratorEvent<BaseActiveGuideContext>
            ) => {
              const ctx = getActivePropertyContext(_ctx);
              const { _hipId, _SOWId } = event.data;
              const idx = ctx.activeGuides.findIndex(
                (guide) => guide._hipId === _hipId && guide._SOWId === _SOWId
              );

              if (idx !== -1) {
                ctx.activeGuides[idx] = event.data;
              } else {
                ctx.activeGuides.push(event.data);
              }
            }
          ),
        ],
        done: [
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<OrchestratorGuideDoneEventData>
          ) => {
            const ctx = getActivePropertyContext(_ctx);
            const { hooks } = event.data.custom || {};

            if (hooks && hooks.before) {
              hooks.before({
                ctx,
                event,
                t,
                navigate,
              });
            }
          },
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<OrchestratorGuideDoneEventData>
          ) => {
            const ctx = getActivePropertyContext(_ctx);
            const propertyId = ctx.property.id;
            const { originalRoomId, hipId, custom, result } = event.data;

            if (
              custom &&
              typeof custom === "object" &&
              custom.hooks?.navigate
            ) {
              custom.hooks.navigate({
                ctx,
                event,
                t,
                navigate,
              });
            } else {
              let navigateUrl = HIPS_ROOM({
                propertyId,
                roomId: originalRoomId,
                hipsId: hipId,
              });

              if (IS_TRY_IT_OUT_ENABLED) {
                const nextGuide = result._SOWGuides[0];
                navigateUrl = TRY_ROUTES_PATHS.GUIDE_RESULT({
                  guideType: nextGuide.type,
                });
              }
              navigate(navigateUrl);
            }
          },
          assign(
            (
              _ctx: OrchestratorMachineContext,
              event: OrchestratorEvent<OrchestratorGuideDoneEventData>
            ) => {
              _ctx.properties = _ctx.properties.map((ctx) => {
                if (_ctx.state.activePropertyId !== ctx.property.id) {
                  return ctx;
                }

                const { result, hipId, originalRoomId } = event.data;
                const hipIdx = ctx.hips.findIndex(
                  (h) => h.id === event.data.hipId
                );

                if (hipIdx === -1) {
                  throw new Error(
                    `Cannot finish the guide: HIP with id ${hipId} not found!`
                  );
                }

                const hip = ctx.hips[hipIdx];

                const roomIdx = hip.planningAreas.rooms.findIndex(
                  (r) => r.id === originalRoomId
                );

                if (roomIdx === -1) {
                  throw new Error(
                    `Cannot finish the guide: Room with id ${originalRoomId} not found!`
                  );
                }

                const room = ctx.hips[hipIdx].planningAreas.rooms[roomIdx];

                const guideResult = {
                  ...room.guideResults,
                };

                /**
                 * Note(pavel): we don't want to introduce more flags
                 * or update all machines with IDK_DONE state.
                 */
                if (isGuideOnIDKPause(result)) {
                  return ctx;
                }

                guideResult.cs = mergeGuideResults(
                  guideResult.cs,
                  result.cs,
                  originalRoomId
                );
                guideResult.add = mergeGuideResults(
                  guideResult.add,
                  result.add,
                  originalRoomId
                );
                guideResult.rpl = mergeGuideResults(
                  guideResult.rpl,
                  result.rpl,
                  originalRoomId
                );
                guideResult.mv = mergeGuideResults(
                  guideResult.mv,
                  result.mv,
                  originalRoomId
                );
                guideResult.rem = mergeGuideResults(
                  guideResult.rem,
                  result.rem,
                  originalRoomId
                );

                const guideResults: RoomGuideResults = {
                  cs: guideResult.cs,
                  [GuideIntentType.REMOVE]: guideResult.rem,
                  [GuideIntentType.REPLACE]: guideResult.rpl,
                  [GuideIntentType.MOVE]: guideResult.mv,
                  [GuideIntentType.ADD]: guideResult.add,
                  machines: uniqBy(
                    [...(guideResult.machines ?? []), ...result._machines],
                    (entry) => `${entry.machineId}_${entry.roomId}`
                  ),
                };

                const hips = ctx.hips.map((_hip, _hipIdx) => {
                  if (_hipIdx !== hipIdx) {
                    return _hip;
                  } else {
                    const rooms = _hip.planningAreas.rooms.map(
                      (_room, _roomIdx) => {
                        const newRoom: RoomAndSpace =
                          _roomIdx !== roomIdx
                            ? _room
                            : { ...room, guideResults };

                        return newRoom;
                      }
                    );

                    return {
                      ..._hip,
                      planningAreas: {
                        ..._hip.planningAreas,
                        rooms,
                      },
                    };
                  }
                });

                const dataToSave = processGuideSpecificData(
                  {
                    ...ctx,
                    hips,
                  },
                  event.data
                );

                return dataToSave;
              });
            }
          ),
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<OrchestratorGuideDoneEventData>
          ) => {
            const ctx = getActivePropertyContext(_ctx);
            const { hooks } = event.data.custom || {};

            if (hooks && hooks.after) {
              hooks.after({
                ctx,
                event,
                t,
                navigate,
              });
            }
          },
          assign(
            (
              _ctx: OrchestratorMachineContext,
              event: OrchestratorEvent<OrchestratorGuideDoneEventData>
            ) => {
              const { result, hipId } = event.data;

              _ctx.properties = _ctx.properties.map((ctx) => {
                if (_ctx.state.activePropertyId !== ctx.property.id) {
                  return ctx;
                }

                const hipIdx = ctx.hips.findIndex(
                  (h) => h.id === event.data.hipId
                );

                if (hipIdx === -1) {
                  throw new Error(
                    `Cannot finish the guide: HIP with id ${hipId} not found!`
                  );
                }

                const hips = ctx.hips.map((hip, _hipIdx) => {
                  if (_hipIdx !== hipIdx) {
                    return hip;
                  } else {
                    if (isGuideOnIDKPause(result)) {
                      return hip;
                    }

                    const addedSOW = result._SOWGuides.filter(
                      (_SOWGuide) =>
                        !hip.scopeOfWork.guides.some(
                          (guide) => guide.id === _SOWGuide.id
                        )
                    );

                    const connectedSowGuidesIds = result._SOWGuides
                      .filter((_SOWGuide) => {
                        return result._meta.relatedItemTypes?.includes(
                          _SOWGuide.itemType
                        );
                      })
                      .map((_SOWGuide) => _SOWGuide.id);

                    const cleanedUpGuides = hip.scopeOfWork.guides.map(
                      (_guide) => {
                        const done =
                          _guide.id === result._SOWId ||
                          connectedSowGuidesIds.includes(_guide.id);

                        if (!done) {
                          return _guide;
                        }

                        const completedIntents = result._machines.map(
                          (m) => m.meta.intent
                        );
                        const targetCompletedIntents =
                          result._SOWGuides.find((g) => g.id === result._SOWId)
                            ?.intents || [];
                        const newCompletedIntents = uniq(
                          [
                            ...completedIntents,
                            ...targetCompletedIntents,
                          ].filter(Boolean)
                        );

                        return {
                          ..._guide,
                          status: OrchestratorSOWGuideStatus.DONE,
                          completedIntents: uniq([
                            ..._guide.completedIntents,
                            ...newCompletedIntents,
                          ]),
                        };
                      }
                    );

                    for (const intent of GUIDE_INTENTS_ORDER) {
                      for (const item of result[intent].items) {
                        const itemType = item.category;

                        const SOWIndex = cleanedUpGuides.findIndex(
                          (g) =>
                            g.itemType === itemType && item.roomId === g.roomId
                        );

                        if (SOWIndex === -1) {
                          cleanedUpGuides.push({
                            id: uuid(),
                            itemType,
                            subCategory: itemType as unknown as SubCategory,
                            type: result._meta.type,
                            roomId: item.roomId,
                            status: OrchestratorSOWGuideStatus.DONE,
                            intents: [intent],
                            completedIntents: [intent],
                          });
                        } else {
                          const value = cleanedUpGuides[SOWIndex];

                          cleanedUpGuides[SOWIndex] = {
                            ...value,
                            intents: uniq([
                              ...value.intents,
                              intent,
                            ]) as OrchestratorSOWGuide["intents"],
                            completedIntents: uniq([
                              ...(value?.completedIntents || []),
                              intent,
                            ]),
                          };
                        }
                      }
                    }

                    let SOWGuides = [...cleanedUpGuides, ...addedSOW];

                    if (
                      result._SOWGuidesToRemove &&
                      result._SOWGuidesToRemove.length > 0
                    ) {
                      for (const guide of result._SOWGuidesToRemove) {
                        const idx = SOWGuides.findIndex(
                          (g) => g.id === guide.id
                        );

                        if (idx === -1) {
                          continue;
                        }

                        const SOWGuide = SOWGuides[idx];
                        const newIntents = (SOWGuide.intents || []).filter(
                          (ci) => !guide.intents.includes(ci)
                        );

                        if (newIntents.length === 0) {
                          SOWGuides[idx] = null;
                        } else {
                          const newCompletedIntents = (
                            SOWGuide.completedIntents || []
                          ).filter((ci) => !guide.intents.includes(ci));

                          SOWGuides[idx] = {
                            ...SOWGuide,
                            completedIntents: newCompletedIntents,
                            intents: newIntents as GuideIntents,
                          };
                        }
                      }

                      SOWGuides = SOWGuides.filter(Boolean);
                    }

                    return {
                      ...hip,
                      scopeOfWork: {
                        ...hip.scopeOfWork,
                        guides: SOWGuides,
                        fakeGuides: hip.scopeOfWork.fakeGuides.filter(
                          (_guide) => _guide.id !== result._SOWId
                        ),
                      },
                    };
                  }
                });

                const activeGuides = isGuideOnIDKPause(result)
                  ? ctx.activeGuides
                  : ctx.activeGuides.filter(
                      (guide) => guide._SOWId !== result._SOWId
                    );

                return {
                  ...ctx,
                  activeGuides,
                  hips,
                };
              });
            }
          ),
        ],
        saveInspirationMapping: assign(
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<ISaveInspirationMappingData>
          ) => {
            const ctx = getActivePropertyContext(_ctx);
            const { mapping, activeCategory, activeCategoryItem } = event.data;
            ctx.inspiration.savedInspiration[activeCategory][
              activeCategoryItem
            ].push(mapping);
          }
        ),
        savePinnedPhoto: assign(
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<SavePinnedPhotoData>
          ) => {
            const { media } = getActivePropertyContext(_ctx);
            const { roomId, pinnedPhoto } = event.data;

            media.pinnedPhotos[roomId] = pinnedPhoto;
          }
        ),
        removePinnedPhoto: assign(
          (
            _ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<RemovePinnedPhotoData>
          ) => {
            const { media } = getActivePropertyContext(_ctx);
            const { roomId } = event.data;

            delete media.pinnedPhotos[roomId];
          }
        ),
        setHasSeenGuideCTA: assign((ctx: OrchestratorMachineContext) => {
          ctx.state.hasSeenGuideCTA = true;
        }),
        setIsMeasurementsShown: assign(
          (
            ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<MeasurementsShownData>
          ) => {
            ctx.state.isMeasurementsShown = event.data.isMeasurementsShown;
          }
        ),
        setIsBudgetBlockOpen: assign(
          (
            ctx: OrchestratorMachineContext,
            event: OrchestratorEvent<boolean>
          ) => {
            ctx.state.isGuideBudgetBlockOpen = event.data;
          }
        ),
      },
    },
    setActivePropertyId: assign(
      (
        ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<OrchestratorSetActivePropertyEventData>
      ) => {
        if (event?.data?.propertyId) {
          ctx.state.activePropertyId = event.data.propertyId;
        }
      }
    ),
    editRoomsAndSpaces: {
      saveChanges: assign(
        (
          _ctx: OrchestratorMachineContext,
          event: OrchestratorEvent<EditRoomsAndSpacesSaveEventData>
        ) => {
          const ctx = getActivePropertyContext(_ctx);
          const { rooms, media, hips } = ctx;
          const { editedRooms } = event.data;

          const deletedRoomsIds = new Set(
            editedRooms
              .filter((eRoom) => eRoom.editType === "removed")
              .map((eRoom) => eRoom.room.id)
          );

          ctx.rooms = rooms.filter((room) => !deletedRoomsIds.has(room.id));

          updateMediaCoverPhotos(editedRooms, media);
          updateRoomsWithEditedChanges(rooms, editedRooms);
          updateHipsRoomsWithEditedChanges(hips, editedRooms);

          const addedRooms = editedRooms
            .filter((eRoom) => eRoom.editType === "added")
            .map((aeRoom) => aeRoom.room);
          ctx.rooms.push(...addedRooms);
          ctx.rooms = getPlanningRoomsList(ctx.rooms);
          ctx.rooms = getUpdatedRoomNames(t, [...ctx.rooms]);

          // TODO (Andrei): In case we need to update hips name and types
          // however we still need to figure out what should we do when we delete a room

          //   const updatedRoomsMap = ctx.rooms.reduce((result, room) => {
          //     result[room.id] = room;
          //     return result;
          //   });

          //   hips.forEach((hip) => {
          //     const { planningAreas } = hip;
          //     planningAreas.rooms.forEach((room) => {
          //       const updatedRoom = updatedRoomsMap[room.id];
          //       if (updatedRoom) {
          //         room.name = updatedRoom.name;
          //         room.type = updatedRoom.type;
          //         room.category = updatedRoom.category;
          //       }
          //     });
          //   });
        }
      ),
    },
  };

  return { actions };
};
