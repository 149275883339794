export const hardReloadPage = () => {
  // Note(pavel): window.reload doesn't work in some specific mobile cases
  window.location.href = `${window.location.href}`;
};

export const getCurrentUrl = () =>
  window.location.pathname + window.location.search;

export const openExternalUrl = (url: string) => {
  window.open(url, "_blank", "noopener noreferrer");
};
