import {
  OrchestratorGuideDoneEventData,
  OrchestratorPropertyContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { GuideMachineId } from "pages/Guides/enums";
import { GuideContext } from "pages/Guides/GuidesMap.types";
import { hackShapesToClosedShape } from "shared/floorPlan/utils/baseShape.utils";
import { getPolygonSQFT } from "shared/floorPlan/utils/polygon.utils";

export const processAddRoomLayoutGuideDone = (
  oldContext: OrchestratorPropertyContext,
  doneData: OrchestratorGuideDoneEventData
): OrchestratorPropertyContext => {
  const { originalRoomId } = doneData;

  const result =
    doneData.result as GuideContext[GuideMachineId.ADD_ROOM_LAYOUT];

  return {
    ...oldContext,
    rooms: oldContext.rooms.map((room) => {
      if (room.id !== originalRoomId) {
        return room;
      }

      // TODO: Taiga/1711 Get real size in units, this is temporary hack.
      // Note(pavel): we probably should calculate it earlier, probably somewhere around measurements.
      const realSize = getPolygonSQFT(
        hackShapesToClosedShape(result._baseShapeItem.shapes),
        {}
      );

      return {
        ...room,
        roomPlan: {
          baseShape: result._baseShapeItem,
          wallMeasurements: result._wallMeasurementValues,
          originbaseShape: result._originBaseShape,
          realSize,
        },
      };
    }),
  };
};
