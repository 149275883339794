export const beautifyLargeNumber = (
  value: number,
  options?: { doNotRound?: boolean }
) => {
  const { doNotRound = false } = options || {};

  if (!value) {
    return value;
  }

  let result = value;

  if (!doNotRound) {
    result = Math.round(value);
  }

  return result.toLocaleString("en-US");
};

export const getIntegerValue = (value: string) =>
  Number(value.replace(/\D/g, "")) || 0;

export const countPositiveNumberDigits = (num: number): number => {
  return num ? Math.floor(num).toString().length : 0;
};

export const toFixed = (value: number) => value.toFixed(8);
