import { forwardRef, useCallback } from "react";
import { IMaskInput } from "react-imask";

export interface MaskedInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
  defenitions?: Record<string, RegExp>;
}

export const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
  (props, ref) => {
    const { onChange, mask, defenitions, ...other } = props;

    const onAccept = useCallback(
      (value: string) => {
        onChange({ target: { name: props.name, value } });
      },
      [onChange, props.name]
    );
    return (
      <IMaskInput
        {...other}
        mask={mask}
        definitions={defenitions}
        inputRef={ref}
        onAccept={onAccept}
        overwrite
      />
    );
  }
);
MaskedInput.displayName = "MaskedInput";
