import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { GuideIntentType } from "pages/Guides/enums";

import { InteriorFloorMaterialConfigFloorPlan } from "./config.floorplan";
import { InteriorFloorMaterialsConfigImages } from "./config.images";
import { InteriorFloorMaterialConfigImages } from "./config.texts";
import { InteriorFloorMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL,
  InteriorFloorMaterialTypes
>;

//Note(Adam): Vinyl hidden for now
const types: Config["types"] = [
  InteriorFloorMaterialTypes.TILE,
  InteriorFloorMaterialTypes.WOOD,
  InteriorFloorMaterialTypes.CARPET,
  InteriorFloorMaterialTypes.BRICK,
  InteriorFloorMaterialTypes.CONCRETE,
  InteriorFloorMaterialTypes.NO_MATERIAL,
];

export const InteriorFloorMaterialsConfig: Config = {
  guideType: GuideType.FLOOR_MATERIAL,
  category: GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL,
  types,
  typesOrder: getOrderedMap(types),
  texts: InteriorFloorMaterialConfigImages,
  images: InteriorFloorMaterialsConfigImages,
  floorPlan: InteriorFloorMaterialConfigFloorPlan,
  allowedIntents: [
    GuideIntentType.REMOVE,
    GuideIntentType.REPLACE,
    GuideIntentType.ADD,
  ],
  allowedInRooms: undefined,
} as const;
