import { TextFieldProps } from "@mui/material";
import { HBInput } from "components/HBInput/HBInput";
import { FC } from "react";

interface Props {
  name: string;
  label: string;
  value: string;
  errorText: string;
  touched: boolean;
  helperText?: string;
  "data-testid"?: string;
  textareaClassName?: string;
  isTextarea?: boolean;
}

export const SidebarInput: FC<Props & TextFieldProps> = ({
  errorText,
  touched,
  value,
  label,
  helperText,
  ...rest
}) => {
  const showError = touched && Boolean(errorText);

  return (
    <HBInput
      value={value}
      label={value && label}
      error={showError}
      fullWidth
      errorText={errorText}
      helperText={helperText}
      {...rest}
    />
  );
};
