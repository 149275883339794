import {
  FormHelperTextProps,
  InputBaseComponentProps,
  InputLabelProps,
  InputProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import clsx from "clsx";
import {
  MaskedInput,
  MaskedInputProps,
} from "components/MaskedInput/MaskedInput";
import {
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useMemo,
} from "react";

import styles from "./HBInput.module.scss";

interface Props {
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  readOnly?: boolean;
  shrink?: boolean;
  errorText?: string;
  helperText?: string;
  isTextarea?: boolean;
  textareaClassName?: string;
  inputClassName?: string;
  mask?: string;
  defenitions?: Record<string, RegExp>;
}
const HBInputComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  Props & TextFieldProps
> = (props, ref) => {
  const {
    startAdornment,
    endAdornment,
    readOnly,
    shrink,
    className,
    errorText,
    helperText,
    isTextarea,
    inputClassName,
    textareaClassName,
    mask,
    error,
    defenitions,
    ...rest
  } = props;

  const inputClasses = clsx(styles.input, inputClassName);

  const CustomMaskedInput = useMemo(() => {
    const CustomMaskedInput = forwardRef(
      (props: MaskedInputProps, ref: RefObject<HTMLInputElement>) => (
        <MaskedInput
          {...props}
          mask={mask}
          defenitions={defenitions}
          ref={ref}
        />
      )
    );
    CustomMaskedInput.displayName = "CustomMaskedInput";
    return CustomMaskedInput;
  }, [mask, defenitions]);

  const InputProps = useMemo<InputProps>(
    () => ({
      startAdornment,
      endAdornment,
      className: inputClasses,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: mask ? (CustomMaskedInput as any) : undefined,
    }),
    [startAdornment, endAdornment, inputClasses, CustomMaskedInput, mask]
  );
  const baseInputProps = useMemo<InputBaseComponentProps>(
    () => ({ readOnly }),
    [readOnly]
  );

  const inputLabelProps = useMemo<InputLabelProps>(
    () => ({ shrink, className: styles.label }),
    [shrink]
  );

  const helperTextProps: Partial<FormHelperTextProps> = useMemo(
    () => ({
      className: clsx(styles.inputHelper, error && styles.error),
      "data-testid": "HelperText",
    }),
    [error]
  );

  const textareaProps = useMemo(
    () => ({
      className: clsx(styles.textarea, textareaClassName),
    }),
    [textareaClassName]
  );

  return (
    <TextField
      InputProps={isTextarea ? textareaProps : InputProps}
      inputProps={baseInputProps}
      InputLabelProps={inputLabelProps}
      className={clsx(
        isTextarea ? styles.textareaWrapper : styles.inputWrapper,
        className
      )}
      FormHelperTextProps={helperTextProps}
      error={error}
      helperText={error ? errorText : helperText}
      {...rest}
      ref={ref}
    />
  );
};

export const HBInput = forwardRef(HBInputComponent);
