export enum CSLightingFixtureMachineStates {
  CS_LIGHTING_FIXTURE_START = "CS_LIGHTING_FIXTURE_START",
  CS_LIGHTING_FIXTURE_0 = "CS_LIGHTING_FIXTURE_0",
  CS_LIGHTING_FIXTURE_1 = "CS_LIGHTING_FIXTURE_1",
  CS_LIGHTING_FIXTURE_2 = "CS_LIGHTING_FIXTURE_2",
  CS_LIGHTING_FIXTURE_3 = "CS_LIGHTING_FIXTURE_3",
  CS_LIGHTING_FIXTURE_4 = "CS_LIGHTING_FIXTURE_4",
  CS_LIGHTING_FIXTURE_5 = "CS_LIGHTING_FIXTURE_5",
  CS_LIGHTING_FIXTURE_SAVED = "CS_LIGHTING_FIXTURE_SAVED",
  CS_LIGHTING_CONTROL_1 = "CS_LIGHTING_CONTROL_1",
  CS_LIGHTING_CONTROL_2 = "CS_LIGHTING_CONTROL_2",
  CS_LIGHTING_FIXTURE_IDK_DONE_1 = "CS_LIGHTING_FIXTURE_IDK_DONE_1",
  CS_LIGHTING_FIXTURE_IDK_DONE_2 = "CS_LIGHTING_FIXTURE_IDK_DONE_2",
}
